import React, { FunctionComponent, useState, useEffect } from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import {
  faStar,
  faMountain,
  faCheckDouble,
  faHandMiddleFinger,
  faExclamationCircle,
  faPlay,
  faShieldAlt,
  faSmileBeam,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons"
import reset from "../images/reset.mp4"
import happiness from "../images/happiness.mp4"
import mantra from "../images/mantra.mp4"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import tedx from "../images/tedx.png"
import herogif from "../images/will-gif-web.webp"
import herogif1 from "../images/will_gif-optimized.gif"

import clsx from "clsx"

import Button from "../components/Button"
import Layout from "../components/Layout"
import OnScreen from "../components/OnScreen"
import SEO from "../components/SEO"
import { SuccessStory } from "../types"
import TypedText from "../components/TypedText"
import StartButton from "../components/StartButton"

interface Props {
  data: any
}

const Coaching: FunctionComponent<Props> = ({ data }) => {
  const stories = data.allContentfulSuccessStory.edges.map(
    (elem: any) => elem.node
  ) as SuccessStory[]

  const [loadVideo, setLoadVideo] = useState(false)
  const [gifLoad, setGifLoad] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLoadVideo(true)
    }, 2500)

    setTimeout(() => {
      setGifLoad(true)
    }, 6000)
  }, [])

  function getTestimonial() {
    const order = ["Linus Stieldorf", "Danny Lynch"]
    const testimonials = []

    for (let i = 0; i < order.length; i += 1) {
      for (let j = 0; j < stories.length; j += 1) {
        if (order[i] === stories[j].name) {
          const elem = stories[j]

          testimonials.push(
            <OnScreen
              className="bg-gray p-4 rounded-md shadow-md"
              classToAdd="slide-in-left"
              key={elem.id}
            >
              <div className="flex items-center mb-8">
                <div className="h-28 w-28">
                  <GatsbyImage
                    alt={elem.title}
                    className="rounded-full filter drop-shadow-lg"
                    image={elem.coverImage.gatsbyImageData}
                  />
                </div>

                <div className="flex-1 ml-8">
                  <p className="font-Orbitron mb-1 text-2xl">{elem.name}</p>

                  <p>{elem.title}</p>
                </div>
              </div>
              <p className="pb-4">{elem.testimonial.testimonial}</p>
              {i != 0 && (
                <div className="mt-8 text-center">
                  <Link to="/success-stories" target="_blank">
                    <Button>View More</Button>
                  </Link>
                </div>
              )}
            </OnScreen>
          )
        }
      }
    }

    return testimonials
  }

  function getTestimonials() {
    const order = [
      "Nick Johnson",
      "Harrison Loew",
      "Kanishka Desai",
      "Linus Stieldorf",
    ]
    const testimonials = []

    for (let i = 0; i < order.length; i += 1) {
      for (let j = 0; j < stories.length; j += 1) {
        if (order[i] === stories[j].name) {
          const elem = stories[j]

          testimonials.push(
            <OnScreen
              className="bg-gray p-4 rounded-md shadow-md"
              classToAdd="slide-in-left"
              key={elem.id}
            >
              <div className="flex items-center mb-8">
                <div className="h-28 w-28">
                  <GatsbyImage
                    alt={elem.title}
                    className="rounded-full filter drop-shadow-lg"
                    image={elem.coverImage.gatsbyImageData}
                  />
                </div>

                <div className="flex-1 ml-8">
                  <p className="font-Orbitron mb-1 text-2xl">{elem.name}</p>

                  <p>{elem.title}</p>
                </div>
              </div>
              <p className="pb-4">{elem.testimonial.testimonial}</p>
            </OnScreen>
          )
        }
      }
    }

    return testimonials
  }
  const [text, setText] = useState("Fire on All Cylinders")
  useEffect(() => {
    const interval = setInterval(() => {
      setText(t => {
        if (t === "Fire on All Cylinders") {
          return "Contact Now"
        }

        return "Fire on All Cylinders"
      })
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Layout>
      <SEO
        title="Life Coach for Young Adults"
        description="Looking for a Life coach for young adults? A simple, fun, and frictionless gamification system to cut through the noise in order to build momentum and level-up."
        link="https://mooremomentum.com/life-coach-for-young-adults/"
      />

      <div className="bg-blue mt-4">
        <div className="container mx-auto px-4">
          <h1 className="font-Orbitron py-4 text-2xl text-white sm:text-3xl lg:py-8 lg:text-4xl">
            Life Coach for Young Adults
          </h1>
        </div>
      </div>
      <div className="container text-center mt-12 gap-8 grid grid-cols-1 items-center mx-auto px-4 text-black md:grid-cols-1 2xl:max-w-7xl">
        <p className="font-Orbitron pt-4 text-shadow text-2xl lg:leading-tight md:text-4xl xl:leading-tight md:text-black md:px-12">
          What if leveling up in life was as fun as playing your favorite game?
        </p>
      </div>
      <div className="container mt-12 mx-auto px-4 mb-12 pb-8">
        <div className="gap-8 grid grid-cols items-center mt-16 sm:grid-cols-2">
          <div className="order-1 pb-16 md:order-2 md:pb-0">
            <div className="max-w-xs mx-auto relative  md:max-w-md xl:max-w-lg delay-1000">
              <div className="bg-yellow bottom-0 hero-image-shadow h-3/4 left-0 right-0 rounded-md relative">
                <picture className="rounded-md">
                  <img
                    srcSet={tedx}
                    // type="image/webp"
                    alt="hero"
                    className="rounded-md absolute"
                    width={"26%"}
                    height="100%"
                    style={{ top: "2%", left: "2%" }}
                  />
                </picture>
                <picture className="rounded-md">
                  <img
                    src={herogif1}
                    alt="hero"
                    className="rounded-md"
                    width={"100%"}
                    height="100%"
                    loading="lazy"
                  />
                </picture>
                <div
                  className="absolute  slide-in-bottom slide-in-bottom--slow w-28 z-0 sm:w-32  2xl:w-48"
                  style={{ top: "-9%", right: "-9%" }}
                >
                  <StaticImage
                    alt="Rocket Ship"
                    placeholder="blurred"
                    src="../images/rocket.webp"
                    loading={"lazy"}
                  />
                </div>
              </div>
              <div className="text-center">
                <p className="border-2 border-coral font-Press-Start inline-block mt-8 mx-auto p-2 text-white md:text-lg">
                  <span className="" style={{ color: "#111c4e" }}>
                    Current Level:
                  </span>{" "}
                  <span className="text-coral">25</span> <br />{" "}
                  <div
                    className="flex flex-wrap justify-center items-center text-core"
                    style={{ color: "#111c4e" }}
                  >
                    and climbing
                    <TypedText
                      className="font-Press-Start mx-auto text-center text-black md:text-lg"
                      text="..."
                    />
                  </div>
                </p>
              </div>
            </div>
          </div>

          <div>
            <OnScreen classToAdd="slide-in-right">
              <div className="space-y-4">
                <h2 className="font-Orbitron mb-8 text-2xl lg:text-4xl">
                  <TypedText
                    className="font-Press-Start text-left text-xl mx-auto text-coral leading-snug md:w-full"
                    text="READY PLAYER ONE???"
                  />
                </h2>

                <ul className="topics-list pl-4">
                  <li className="mt-4">
                    Harness the power of atomic habits, gamification, and proven
                    principles to level-up not just on screen, but in REAL LIFE!
                  </li>
                  <li className="mt-4">
                    Ignite your momentum by customizing your habits to your
                    unique personality, goals, and lifestyle.
                  </li>
                  <li className="mt-4 mb-4">
                    Have FUN getting unstuck and building holistic{" "}
                    <Link to="/success-habits/" className="text-blue">
                      success habits{" "}
                    </Link>{" "}
                    in your{" "}
                    <Link to="/5-core-areas-of-life/" className="text-blue">
                      5 Core Areas Of Life
                    </Link>
                    .
                  </li>
                </ul>
                <div className="relative z-10 " id="hero-quiz-button">
                  <Link
                    className={clsx(
                      "m-auto animate-pulse bg-coral border-2 border-black flex font-Press-Start xl:h-52 l:h-44 h-44 quiz-button rounded-full shadow-lg transition-opacity uppercase xl:w-52 l:w-44 w-44 hover:opacity-70 text-white"
                    )}
                    to="#contact"
                  >
                    <span className="bg-coral border-2 border-gray-dark flex font-Press-Start h-full items-center rounded-full start-button-shadow text-center transform translate-x-2 flex justify-center items-center -translate-y-2 w-full xl:p-4 ">
                      {text}
                    </span>
                  </Link>{" "}
                </div>
              </div>
            </OnScreen>
          </div>
        </div>
      </div>

      <div className="max-w-5xl mx-auto mb-16 mt-8 px-4 lg:mb-24">
        <div className="space-y-8">{getTestimonial()}</div>
      </div>

      <div className="max-w-6xl my-16 mx-auto px-4 lg:my-24">
        <h2 className="font-Orbitron mb-8 text-center text-3xl">As Seen On</h2>

        <div className="flex flex-wrap items-center sm:flex-nowrap sm:space-x-8 lg:space-x-16">
          <div className="mb-8 text-center w-1/2 sm:mb-0 sm:w-auto">
            <StaticImage
              alt="Tedx Logo"
              className="w-32 sm:w-auto"
              src="../images/tedx.png"
            />
          </div>

          <div className="mb-8 text-center w-1/2 sm:mb-0 sm:w-auto">
            <StaticImage
              alt="ABC Logo"
              className="h-24 w-32 sm:w-auto"
              objectFit="contain"
              src="../images/abc.png"
            />
          </div>

          <div className="mb-8 text-center w-1/2 sm:mb-0 sm:w-auto">
            <StaticImage
              alt="NBC Logo"
              className="w-32 sm:w-auto"
              src="../images/nbc.png"
            />
          </div>

          <div className="mb-8 text-center w-1/2 sm:mb-0 sm:w-auto">
            <StaticImage
              alt="Entrepreneur Logo"
              className="w-32 sm:w-auto"
              src="../images/entrepreneur.png"
            />
          </div>

          <div className="text-center w-1/2 sm:w-auto">
            <StaticImage
              alt="OBJ Logo"
              className="w-24 sm:w-auto"
              src="../images/obj.png"
            />
          </div>

          <div className="text-center w-1/2 sm:w-auto">
            <StaticImage
              alt="CW Logo"
              className="w-32 sm:w-auto"
              src="../images/cw.png"
            />
          </div>
        </div>
      </div>

      <div className="container mt-12 mx-auto px-4 mb-12 pb-8">
        <OnScreen classToAdd="slide-in-right">
          <div className="space-y-4">
            <h2 className="font-Orbitron mb-8 text-2xl lg:text-4xl">
              <Link className="link" to="/gamify-your-life/">
                {" "}
                Gamify Your Habits{" "}
              </Link>
              To{" "}
              <Link className="link" to="/">
                Level Up Your Life
              </Link>
            </h2>
            <p className="topics-list pl-4">
              Change sucks. It's hard. It's boring, it takes too long to see
              results, and in a tech fueled world with a million voices coming
              at us it's become impossible to know what coaching and motivation
              advice to listen to.
            </p>{" "}
            <p>
              Is that trending influencer or motivational coach really trying to
              help me, or just trying to monetize my attention?
            </p>
            <p>
              And even if we were magically given the answers to the exact
              things we needed to do that would make us happy in daily life
              again, we still need to take action on those things enough times
              for them to become habits.
            </p>{" "}
            <p>
              But with our ever increasing ability to grab the low hanging fruit
              and get whatever we want at the click of a button, our discipline
              to do what's best for us is rapidly eroding. Maintaining focus and
              finding the right support to get get moving in the right direction
              and make real progress is darn near is darn near impossible to
              overcome these days.
            </p>{" "}
            <p className="mb-8">
              Here's the thing about habits. They don't care if they're good or
              bad, helping or hurting us, either way they'll compound to form
              who you are via what I call {" "}
              <strong>"The Equation Of Life."</strong>
            </p>{" "}
            <div className="px-4 mt-12 pt-12 pb-12">
              <OnScreen classToAdd="slide-in-right">
                <div className="bg-white max-w-lg mx-auto p-4 relative rounded-md shadow-lg">
                  <div className="absolute bg-blue h-24 -left-4 -top-4 w-24 -z-1" />
                  <div className="absolute bg-blue -bottom-4 h-24 -right-4 w-24 -z-1" />

                  <StaticImage
                    alt="Will Moore Speaking"
                    placeholder="blurred"
                    src="../images/equation-of-life-v2.jpg"
                  />
                </div>
              </OnScreen>
            </div>
            <p className="topics-list text-black pl-4 text-2xl mt-4 mb-4">
              <strong className="text-black">
                {" "}
                But what if change was as fun as playing your favorite game?{" "}
              </strong>
            </p>{" "}
            <p>
              What if developing life skills and good habits like giving fear
              the finger, eating healthy, and remembering people's names gave
              you the same dopamine hits in your daily life as scrolling through
              social media?
            </p>{" "}
            <p>
              My #1 goal as one of the top motivational coaches in the country
              is to harness the power of momentum to create a world where
              evidence based science and proven principles team-up with the
              latest in technology to ensure. you take those small yet hugely
              impactful actions every single day. In other words, to get the
              above <i>Equation Of Life</i> working FOR instead of against you.
            </p>{" "}
            <p>
              The Moore Momentum system is about finding unique solutions to
              each person's unique struggles to get their lives to realize that
              future, upgraded version of themselves that seems so illusive to
              find.
            </p>
            <p>
              The system drastically reduces the struggle and friction to
              replace your failure habits in the {" "}
              <Link className="link" to="/5-core-areas-of-life/">
                5 Core Areas Of Your LIfe
              </Link>{" "}
              that matter most, and provide. the support, and coaching to
              overcome challenges in life related to these main areas. These are
              your:
            </p>{" "}
            <div className="flex  items-center   md:mt-2">
              <div className="mr-2" style={{ width: "90px" }}>
                <StaticImage
                  alt="5 areas of life mind core of life"
                  placeholder="blurred"
                  src="../images/5-areas-of-life-brain.png"
                />
              </div>

              <p>
                <b> Mindset Core:</b> Getting your mind working FOR instead of
                against you by developing a “growth owner mindset” who fails
                forward, sees obstacles as temporary roadblocks, and gives fear
                the finger.
              </p>
            </div>
            <div className="flex  items-center   md:mt-2">
              <div className="mr-2" style={{ width: "80px" }}>
                <StaticImage
                  alt="5 areas of life mind core of life"
                  placeholder="blurred"
                  src="../images/dollar.png"
                />
              </div>
              <p>
                <b>Career & Finance Core:</b> Earning a living doing what you
                love and are great at, executing your purpose, and exponentially
                growing your wealth along the way.
              </p>
            </div>
            <div className="flex items-center   md:mt-2">
              <div className="mr-2" style={{ width: "80px" }}>
                <StaticImage
                  alt="5 areas of life - communication core of life"
                  placeholder="blurred"
                  src="../images/5-areas-of-life-communication.png"
                />
              </div>
              <p>
                <b>Relationship Core:</b> Creating and maintaining deep,
                fulfilled personal relationships and gaining allies to help each
                other achieve your goals.
              </p>
            </div>
            <div className="flex items-center   md:mt-2">
              <div className="mr-2" style={{ width: "80px" }}>
                <StaticImage
                  alt="5 areas of life physical health"
                  placeholder="blurred"
                  src="../images/dumble.png"
                />
              </div>
              <p>
                <b>Physical Health Core:</b> Taking care of your physical body
                to look good, feel good, and gain the energy and stamina to
                propel you through life.
              </p>
            </div>
            <div className="flex items-center md:mt-2">
              <div className="mr-2" style={{ width: "80px" }}>
                <StaticImage
                  alt="5 areas of life - emotinal core of life"
                  placeholder="blurred"
                  src="../images/5-areas-of-life-yoga.png"
                />
              </div>
              <p>
                <b>Emotional Health Core:</b> Managing stress effectively,
                expressing your passions regularly, and ensuring the world is
                better for having you in it.
              </p>
            </div>
          </div>
        </OnScreen>
      </div>
      <div className="px-4">
        <OnScreen classToAdd="slide-in-right">
          <div className="bg-white max-w-lg mx-auto p-4 relative rounded-md shadow-lg">
            <div className="absolute bg-blue h-24 -left-4 -top-4 w-24 -z-1" />
            <div className="absolute bg-blue -bottom-4 h-24 -right-4 w-24 -z-1" />

            <StaticImage
              alt="Will Moore Speaking"
              placeholder="blurred"
              src="../images/kid-core-gamify-life.png"
            />
          </div>
        </OnScreen>
      </div>
      <div className="container mt-20 mx-auto px-4 mb-12 pb-8">
        <div>
          {/* <div className="space-y-4">
            
            <p className="topics-list pl-4">
              But what if change was as fun as playing your favorite game?
            </p>{" "}
            <p>
              What if developing good habits like giving fear the finger, eating
              healthy, and remembering people's names gave you the same dopamine
              hit as scrolling through social media?
            </p>{" "}
            <p>
              My #1 goal is to harness the power of momentum and play to get you
              ethically addicted to taking those small yet hugely impactful
              actions every single day. To get the Equation Of LIfe working FOR
              instead of against you.
            </p>{" "}
            <p>
              The Moore Momentum system combines my life long study of
              behavioral science and what motivates us to take action with time
              proven principles you don’t have to question the validity of.{" "}
            </p>
            <p>
              My system drastically reduce the friction to replace your failure
              habits with{" "}
              <Link className="link" to="/success-habits/">
                success habits
              </Link>{" "}
              in the{" "}
              <Link className="link" to="/5-core-areas-of-life/">
                5 Core Areas Of Your LIfe
              </Link>{" "}
              that matter most. These are your:
            </p>{" "}
            <ol className="ordered-list pl-4">
              <li>
                <b> Mindset Core:</b> Getting your mind working FOR instead of
                against you by developing a “growth owner mindset” who fails
                forward, sees obstacles as temporary roadblocks, and gives fear
                the finger.
              </li>
              <li>
                <b>Career & Finance Core:</b> Earning a living doing what you
                love and are great at, executing your purpose, and exponentially
                growing your wealth along the way.
              </li>
              <li>
                <b>Relationship Core:</b> Creating and maintaining deep,
                fulfilled personal relationships and gaining allies to help each
                other achieve your goals.
              </li>
              <li>
                <b>Physical Health Core:</b> Taking care of your physical body
                to look good, feel good, and gain the energy and stamina to
                propel you through life.
              </li>
              <li>
                <b>Emotional Health Core:</b> Managing stress effectively,
                expressing your passions regularly, and ensuring the world is
                better for having you in it
              </li>
            </ol>
          </div> */}
        </div>
      </div>
      <div className="container mt-12 mx-auto px-4 mb-12 pb-8">
        <div className="space-y-4">
          <h2 className="font-Orbitron mb-8 text-2xl lg:text-4xl">
            Why Should I LIsten To This Guy?{" "}
          </h2>
          <p className="topics-list pl-4">
            Like all of us, I started life full of wonder, passion, and
            enthusiasm. As a kid, squeezing every drop of joy out of each day
            was my #1 goal and not even the sky was the limit.
          </p>{" "}
          <p>
            But then life got in the way, and by the time I reached my freshman
            year of college my failure habits and mental health issues had
            compounded to the point of being suicidal.
          </p>{" "}
          <p>
            I was your typical <strong> "fixed victim,"</strong> convinced life
            was out to get me and there was nothing my broken brain could do
            about it. Shame, low self confidence, worry, negative emotions, and
            self-doubt became my default belief system that led to habits that
            were making my life a living hell.
          </p>{" "}
          <p>
            But a serendipitous moment (discover it in my “Gamified Bio”
            <Link className="link" to="/why-will-moore/">
              HERE
            </Link>
            ) created a tiny spark that was just enough for me to find the inner
            motivation to get to the next day, then the next, and the next after
            that.
          </p>
          <p>
            I wish I'd known then what I know now -{" "}
            <b>the current system we all grow up in is broken</b>.
          </p>{" "}
          <p>
            From the day we're born, we're at the mercy of our major
            influencers, professional coaches, and motivational coaches to give
            us the knowledge and skills necessary to thrive in life. in our life
            - first our parents, then peers, then coaches, then school, media,
            etc.
          </p>
          <p>
            But they're in the same failure loop we're caught in, passing on a
            belief system with no checks and balances - leading to bad habits
            that crush our momentum.
          </p>
          <p>
            Understanding this warped reality and how to course correct is what
            my motivational coaching is all about, providing the tools to
            overcome whatever personal struggles and challenges are stifling
            your growth.
          </p>
        </div>
      </div>
      <div className="px-4 mb-12">
        <div className="bg-white max-w-lg mx-auto p-4 relative rounded-md shadow-lg">
          <div className="absolute bg-blue h-24 -left-4 -top-4 w-24 -z-1" />
          <div className="absolute bg-blue -bottom-4 h-24 -right-4 w-24 -z-1" />

          <StaticImage
            alt="Gamify Your Life"
            placeholder="blurred"
            src="../images/index-timeline-2.png"
            className="mb-4"
          />
          <StaticImage
            alt="Gamify Your Life"
            placeholder="blurred"
            src="../images/loop.jpg"
          />
        </div>
      </div>
      <div className="container pt-12 mt-12 mx-auto px-4 mb-12 pb-8">
        <div>
          <div className="space-y-4">
            <h2 className="font-Orbitron mb-8 text-2xl lg:text-4xl">
              How To Momentify Your Life{" "}
            </h2>
            <p className="topics-list pl-4">
              Momentum is my favorite law of the universe. We can't see, feel,
              hear, or touch but we all know it's there.
            </p>{" "}
            <p>
              25 years after hitting rock bottom in college, I can proudly say
              that I've succeeded in harnessing the power of momentum by
              proactively by tapping into it via in the {" "}
              <Link className="link" to="/5-core-areas-of-life/">
                5 Core Areas Of Your Life
              </Link>
            </p>{" "}
            <p>
              I'm now a “Growth Owner” who believes I have everything within me
              to kick ass, take names, and fulfill my destiny. And this has made
              a huge difference in my life. I understand that
              <b>
                failure is mandatory, obstacles and struggles are temporary
                roadblocks waiting for solutions, and the only opinion that
                matters is my own.
              </b>
              .
            </p>{" "}
            <p className="text-xl">
              <b>
                The mantra I repeat to myself every morning as part of my
                morning routine habit stack:
              </b>
            </p>
            {loadVideo && (
              <div className="m-auto">
                <video
                  playsinline=""
                  id="vid"
                  controls
                  style={{ margin: "0 auto" }}
                >
                  <source
                    src={
                      "https://videos.ctfassets.net/8kplw9flsxgi/7HgShT2zXynHAoNVagltij/3af8cb23491a980b41ad3b3912b294aa/mantra.mp4#t=0.001"
                    }
                    type="video/mp4"
                  />
                </video>
              </div>
            )}
            <p>
              The Moore Momentum system takes a unique approach to change by
              tricking your brain to WANT to take the right actions that will
              help you shift your belief system.
            </p>{" "}
            <p>
              Stepping into what I call the <strong> success loop </strong>
              where that belief system then feeds your actions, those additions
              taken enough times become habit, and those habits then upgrade
              your belief system . All compounding over time to create the
              person you were always destined to become.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white max-w-lg mx-auto p-4 relative rounded-md shadow-lg">
        <div className="absolute bg-blue h-24 -left-4 -top-4 w-24 -z-1" />
        <div className="absolute bg-blue -bottom-4 h-24 -right-4 w-24 -z-1" />

        <StaticImage
          alt="Gamify Your Life"
          placeholder="blurred"
          loading="eager"
          src="../images/growth-mindset.jpg"
        />
      </div>
      <div className="container mt-12 mx-auto px-4 mb-12 pb-8">
        <p>
          {" "}
          Using behavioral science and universal principles as our guide, we'll
          redesign your real world so it becomes fun and engaging to replace
          your failure habits with success habits in all 5 Cores. To help hack
          your mind so what you HAVE to do becomes what you WANT to do.
        </p>
        <br />
        <p>
          By helping turn your life into a game - where work becomes play - I'll
          reduce the friction and provide tools to allow you to figure out
          exactly WHO you are, WHAT you want, and HOW to get it.
        </p>
      </div>
      <div className="container mt-12 mx-auto px-4 mb-12 pb-8">
        <div className="gap-4 grid grid-cols-1 sm:grid-cols-4 xl:gap-10 lg:grid-cols-3">
          <OnScreen
            className="sm:col-span-2 lg:col-span-1"
            classToAdd="scale-in-center"
          >
            <Link to="/5-core-areas-of-life/">
              <div className="bg-white h-full p-6 relative rounded-md shadow-lg transform transition-transform hover:-translate-y-12">
                <div className="flex items-center mb-8">
                  <div className="flex h-14 icon-gray-bg items-center justify-center p-3 rounded-xl w-14">
                    <StaticImage
                      alt="Tedx"
                      className="w-32 sm:w-auto"
                      src="../images/rocket.webp"
                      style={{ transform: "rotate(40deg" }}
                    />
                  </div>

                  <h3 className="font-Orbitron ml-4 text-blue text-xl xl:text-2xl">
                    {/* Discover Your Five Cores */}
                    WHO you are
                  </h3>
                </div>

                <p>
                  <div className="text-link">
                    Determine the current level you're stuck on and pinpoint the
                    habits that are decimating your momentum.
                  </div>
                </p>
              </div>
            </Link>
          </OnScreen>

          <OnScreen
            className="sm:col-span-2 lg:col-span-1"
            classToAdd="scale-in-center"
          >
            <Link to="/success-habits/">
              <div className="bg-white h-full p-6 rounded-md shadow-lg transform transition-transform hover:-translate-y-12">
                <div className="flex items-center mb-8">
                  <div className="flex h-14 icon-teal-bg items-center justify-center p-3 rounded-xl w-14">
                    <svg
                      width="42"
                      height="41"
                      viewBox="0 0 42 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.2736 3.3085e-05C26.7112 0.00979757 31.6483 2.15257 35.2933 5.63594L38.2239 2.70537C39.4645 1.46479 41.5857 2.34343 41.5857 4.09792V15.0981C41.5857 16.1857 40.7041 17.0674 39.6164 17.0674H28.6163C26.8618 17.0674 25.9832 14.9462 27.2237 13.7055L30.6495 10.2797C28.117 7.90844 24.84 6.59491 21.3585 6.56488C13.7768 6.4994 7.38601 12.635 7.45157 20.4684C7.51376 27.8993 13.5382 34.1347 21.2362 34.1347C24.6109 34.1347 27.8003 32.9303 30.3138 30.7248C30.703 30.3834 31.2908 30.4042 31.6569 30.7702L34.9113 34.0247C35.3111 34.4244 35.2913 35.0762 34.8718 35.4551C31.2634 38.7144 26.4816 40.699 21.2362 40.699C9.99755 40.699 0.886801 31.5883 0.886719 20.3497C0.886637 9.12391 10.0478 -0.0200703 21.2736 3.3085e-05Z"
                        fill="#00A98F"
                      />
                    </svg>
                  </div>

                  <h3 className="font-Orbitron ml-4 text-blue text-xl xl:text-2xl">
                    {/* Reset Your Habits */}
                    WHAT you want
                  </h3>
                </div>
                <p>
                  <div className="text-link">
                    Discover what the ultimate upgraded version of you looks
                    like and identify the success habits to take you there.{" "}
                  </div>
                </p>
              </div>
            </Link>
          </OnScreen>

          <OnScreen
            className="sm:col-span-2 sm:col-start-2 lg:col-span-1"
            classToAdd="scale-in-center"
          >
            <Link to="/gamify-your-life">
              <div className="bg-white h-full p-6 rounded-md shadow-lg transform transition-transform hover:-translate-y-12">
                <div className="flex items-center mb-8">
                  <div className="flex h-14 icon-yellow-bg items-center justify-center p-3 rounded-xl w-14">
                    <svg
                      width="46"
                      height="41"
                      viewBox="0 0 46 41"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M43.8787 5.08738H35.6117V1.90777C35.6117 0.850546 34.7611 0 33.7039 0H12.0825C11.0253 0 10.1748 0.850546 10.1748 1.90777V5.08738H1.90777C0.850546 5.08738 0 5.93793 0 6.99515V11.4466C0 14.2844 1.78853 17.2017 4.92045 19.4513C7.4244 21.2557 10.4689 22.4004 13.6644 22.766C16.1604 26.9075 19.0777 28.6165 19.0777 28.6165V34.3398H15.2621C12.4561 34.3398 10.1748 35.9853 10.1748 38.7913V39.7452C10.1748 40.2698 10.604 40.699 11.1286 40.699H34.6578C35.1824 40.699 35.6117 40.2698 35.6117 39.7452V38.7913C35.6117 35.9853 33.3303 34.3398 30.5243 34.3398H26.7087V28.6165C26.7087 28.6165 29.626 26.9075 32.122 22.766C35.3255 22.4004 38.37 21.2557 40.866 19.4513C43.9899 17.2017 45.7864 14.2844 45.7864 11.4466V6.99515C45.7864 5.93793 44.9359 5.08738 43.8787 5.08738ZM7.89339 15.3257C5.95382 13.9267 5.08738 12.3687 5.08738 11.4466V10.1748H10.1907C10.2701 12.7661 10.6517 15.0396 11.2081 17.0268C10.0078 16.6135 8.88702 16.0411 7.89339 15.3257ZM40.699 11.4466C40.699 12.7264 39.2921 14.3162 37.893 15.3257C36.8994 16.0411 35.7706 16.6135 34.5703 17.0268C35.1268 15.0396 35.5083 12.7661 35.5878 10.1748H40.699V11.4466Z"
                        fill="#FFC629"
                      />
                    </svg>
                  </div>

                  <h3 className="font-Orbitron ml-4 text-blue text-xl xl:text-2xl">
                    {/* Gamify Your LIfe */}
                    HOW to get it
                  </h3>
                </div>

                <p>
                  <div className="text-link">
                    Implement a gamified action and accountability system to
                    ensure those desired habits become INEVITABLE!
                  </div>
                </p>
              </div>
            </Link>
          </OnScreen>
        </div>
        <p
          className="text-center mt-12 pt-4 font-Orbitron"
          style={{ fontSize: "20px" }}
        >
          <strong>
            “First it is <span style={{ color: "#E32901" }}>pain</span>. Then
            awareness. Then <span style={{ color: "#6B2CDF" }}>action</span>.
            Then a <span style={{ color: "#0123E3" }}>habit</span>. Then it is
            simply <span className="text-blue">who you are</span>” - Will Moore
          </strong>
        </p>
      </div>
      <div className="container mt-6 mx-auto px-4 mb-12 pb-8">
        <div>
          <div className="space-y-4">
            <h2 className="font-Orbitron mb-8 text-2xl lg:text-4xl">
              {/* How To Momentify Your Life{" "} */}
            </h2>
            <p className="topics-list pl-4">
              Remember, habits don't care if they're good or bad, helping or
              hurting you. Either way they'll compound over time to do their
              thing via the E.O.L. Once you get over what I call the “front
              loaded hump,” those neurons that fire together will start wiring
              together.
            </p>{" "}
            <p>
              And to ensure this happens we'll customize your habits to your
              unique DNA, personality, and lifestyle to make them obvious, easy,
              and satisfying so you not only WANT to form them, they become
              <b>INEVITABLE</b>.
            </p>{" "}
            <p>
              <b>Upgrading your existing system </b>is a process that{" "}
              <b>broken into 2 main phases</b>
            </p>{" "}
            <p>
              <b>Phase 1: Awareness: </b>
              Become aware of WHO you are, WHO you want to become, and the
              HABITS needed to bridge the two
            </p>
            <p>
              <b>Phase 2: Action/Accountability: </b>
              Have fun and get ethically addicted to taking action on your
              habits until they've become part of WHO YOU ARE. Comment end
            </p>
            <p>
              And once the system is in place, it takes less than 5 minutes a
              day to ensure continual growth is on autopilot.
            </p>{" "}
            <h2
              className="text-center font-Orbitron pt-12 text-2xl lg:text-3xl"
              style={{ fontWeight: "800" }}
            >
              <b>DEVELOP A GROWTH OWNER MINDSET TO WIN THE GAME OF LIFE </b>
            </h2>
            <h2 className="text-xl pt-4 font-Orbitron">
              THINGS A GROWTH OWNER WOULD SAY:
            </h2>
            <p className="flex items-center">
              <FontAwesomeIcon
                className="text-2xl"
                icon={faMountain}
                style={{ color: "gold" }}
              />{" "}
              <span className="ml-2">
                A challenge is an opportunity to grow, and if I fail, I fail
                forward by learning.
              </span>
            </p>
            <p className="flex items-center">
              <div className="flex items-center">
                <div
                  className="flex   items-center justify-center "
                  style={{ width: "20px", marginRight: "10px" }}
                >
                  <StaticImage
                    alt="Tedx"
                    src="../images/rocket.webp"
                    style={{ transform: "rotate(40deg" }}
                  />
                </div>
                <span className="ml-2 topics-list">
                  I harness my strengths, minimize and find workarounds to my
                  "weaknesses".
                </span>
              </div>
            </p>
            <p className="flex items-center">
              <FontAwesomeIcon
                className="text-2xl"
                icon={faStar}
                style={{ color: "#yellow" }}
              />{" "}
              <span className="ml-2">
                 My only limits are on how much effort I'm willing to give.{" "}
              </span>
            </p>
            <p className="flex items-center">
              <FontAwesomeIcon
                className="text-2xl"
                icon={faCheckDouble}
                style={{ color: "green" }}
              />{" "}
              <span className="ml-2">
                I focus on what I CAN control and don't waste a second on what I
                can't.
              </span>
            </p>
            <p className="flex items-center">
              <FontAwesomeIcon
                className="text-2xl"
                icon={faHandMiddleFinger}
                style={{ color: "red" }}
              />{" "}
              <span className="ml-2">
                When fear tries to creep in, I give it the finger.{" "}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="container mt-12 mx-auto px-4 mb-6">
        <div className="space-y-4">
          <h2 className="font-Orbitron mb-8 text-2xl lg:text-4xl">
            THE EVOLUTION FROM FIXED-VICTIM TO GROWTH-OWNER
          </h2>
        </div>
        <div className="px-4">
          <div className="bg-white  mx-auto p-4 relative m-auto flex items-end flex-col">
            <div className="flex m-auto items-center sm:flex-wrap">
              <StaticImage
                alt="Gamify Your Life"
                placeholder="blurred"
                src="../images/evolution-rocket.png"
              />
            </div>
          </div>
        </div>{" "}
      </div>
      <div className="container mt-12 mx-auto px-4 mb-6">
        <div className="space-y-4">
          <h2 className="font-Orbitron mb-8 text-2xl lg:text-4xl">
            I'll Teach You To Become An Entrepreneur Of The Most Important
            Business You'll Ever run - YOUR LIFE!!!
          </h2>
          <p className="font-Orbitron">
            When{" "}
            <a
              href="https://table.skift.com/2018/12/12/waitr-acquires-bite-squad-for-321-million/"
              target="_blank"
              className="text-blue underline"
            >
              I exited my startup for a combined $321 mil
            </a>
            , it felt … damn good.{" "}
          </p>
          <p className="topics-list pl-4">
            But here's the thing nobody talks about in a society centered around
            an insatiable passion to become rich, powerful, and famous. If it
            ever does happen, it's <b>fleeting</b>.
          </p>{" "}
          <p>
            It's a dopamine hit just like clicking that “buy button” on Amazon,
            albeit a larger one, but it goes away and you're left with, “NOW
            WHAT?”
          </p>{" "}
          <p>
            <b>Listen closely. </b>
          </p>{" "}
          <p>
            In my journey of becoming a traditional entrepreneur, the real
            secret to life nobody tells you is to
            <b>treat your entire life like a business</b>.
          </p>
          <p>
            I realized if I put the same dedication to improving each aspect
            that moved the needle in my bank account, I could tap into something
            that was far more gratifying.
          </p>
          <p>
            There's no better feeling than waking up every day fulfilling your
            life's purpose and KNOWING there's nothing that can get in your way
            of goal setting and achieving goals.
          </p>{" "}
          <p>
            Let's hit the reset button on your life to return back to that
            wonder, passion, and enthusiasm you once had as a kid and start
            winning the game of life.
          </p>
          <p className="flex items-center">
            <b>
              Time to learn how to build and run your life like a successful
              startup to become an entrepreneur of the most important business
              you'll ever run - YOUR LIFE!
            </b>
          </p>
          <p className="flex items-center">
            Continually growing and maintaining balance in the fundamental
            contributors to success. But instead of HR, marketing, and
            accounting, in real life it's what I refer to as The Five Cores.
          </p>
          <div className="px-4">
            <div className="bg-white  mx-auto p-4 relative m-auto flex items-end flex-col">
              <OnScreen
                className="bg-blue max-w-2xl mx-auto p-4 rounded-md shadow-lg"
                classToAdd="slide-in-left"
              >
                <div className="bg-gray">
                  <video
                    autoPlay
                    loop
                    muted
                    playsinline=""
                    id="vid"
                    preload="auto"
                  >
                    <source src={reset} type="video/mp4" />
                  </video>
                  <video
                    autoPlay
                    loop
                    muted
                    playsinline=""
                    id="vid"
                    preload="auto"
                  >
                    <source src={happiness} type="video/mp4" />
                  </video>
                </div>
              </OnScreen>
            </div>
          </div>{" "}
          <OnScreen
            className="max-w-4xl mx-auto mb-20 mt-8 px-4 py-8 "
            classToAdd="slide-in-left"
          >
            <TypedText
              className="font-Press-Start  mx-auto text-center text-coral text-4xl leading-snug md:w-full"
              text="READY PLAYER ONE???"
            />
          </OnScreen>
        </div>
      </div>

      <div className="px-4" id="contact"></div>
      <div>
        <h2 className="font-Orbitron mb-8 mt-16 text-center text-3xl lg:mt-24 lg:text-4xl">
          Testimonials
        </h2>

        <div className="max-w-5xl mx-auto mb-16 mt-8 px-4 lg:mb-24">
          <div className="space-y-8">{getTestimonials()}</div>
          <div className="mt-16 text-center">
            <Link to="/success-stories" target="_blank">
              <Button>View More</Button>
            </Link>
          </div>
        </div>
      </div>

      <div className="bg-gray py-16">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="font-Orbitron mb-4 text-2xl md:text-3xl">
            Contact me directly to discuss your/ your company’s needs:
          </h2>

          <p className="mb-8">
            Please provide as much detail as possible and we&apos;ll be in touch
            ASAP!
          </p>

          <form
            name="coaching"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            className="text-black"
          >
            <input type="hidden" name="form-name" value="coaching" />
            <input type="hidden" name="bot-field" />

            <div className="space-y-8">
              <div className="gap-8 grid sm:grid-cols-2">
                <div>
                  <input
                    className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md w-full focus:border-blue"
                    name="first-name"
                    placeholder="First Name"
                    required
                    type="text"
                  />
                </div>

                <div>
                  <input
                    className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md w-full focus:border-blue"
                    name="last-name"
                    placeholder="Last Name"
                    required
                    type="text"
                  />
                </div>
              </div>

              <div className="gap-8 grid sm:grid-cols-2">
                <div>
                  <input
                    className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md w-full focus:border-blue"
                    name="email-address"
                    placeholder="Email Address"
                    required
                    type="email"
                  />
                </div>
                <div className="flex items-center">
                  <div className="flex items-center mr-8">
                    <input
                      className="w-6 h-6 text-blue border-gray-900 ring-0"
                      id="personal"
                      type="radio"
                      name="coaching-for"
                      checked
                    />
                    <label
                      htmlFor="personal"
                      className="ml-2 text-sm font-medium text-gray-900 "
                    >
                      Personal
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      className="w-6 h-6 text-blue border-gray-300 ring-0"
                      id="business"
                      type="radio"
                      name="coaching-for"
                    />
                    <label
                      htmlFor="business"
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Business
                    </label>
                  </div>
                </div>
              </div>

              <div>
                <textarea
                  className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md w-full focus:border-blue"
                  name="problem"
                  placeholder="What are you looking for help with?"
                  rows={8}
                />
              </div>

              <Button className="px-12" type="submit">
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>

      <OnScreen classToAdd="slide-in-right">
        <div className="container mb-12 mx-auto px-4 md:mb-24">
          <div className="grid rounded-md shadow-lg">
            <StaticImage
              alt="App Background Screen"
              className="space-bg rounded-md"
              layout="fullWidth"
              objectPosition="57% 57%"
              placeholder="blurred"
              quality={100}
              src="../images/index-app-bg.png"
              style={{ gridArea: "1/1" }}
            />

            <div className="grid relative" style={{ gridArea: "1/1" }}>
              <div className="absolute bg-black bg-opacity-30 inset-0 rounded-md" />

              <div className="absolute bottom-0 hidden left-0 z-0 md:block md:w-36 xl:w-full">
                <StaticImage
                  alt="Alien"
                  className="w-32"
                  placeholder="blurred"
                  src="../images/alien.png"
                />
              </div>

              <div className="gap-16 grid grid-cols-1 pt-8 px-8 text-white z-10 md:gap-4 md:grid-cols-2 md:pt-0 md:px-0">
                <div className="max-w-md mx-auto space-y-8 z-10 md:max-w-lg md:pl-8 md:pt-8 2xl:self-center">
                  <h2 className="font-Orbitron text-2xl lg:text-4xl">
                    Learn more about the Gamify Your Life App
                  </h2>

                  <p>
                    {/* Be the Pilot of your own rocket ship and learn how to fire
                      on all cylinders. Click below to learn more and be the
                      first to know when our app is released! */}
                    The culmination of 25 years of research, the app will allow
                    you to truly gamify your life by making it fun and addicting
                    to gain momentum. As you progress and level-up on screen,
                    you'll also level up in real life!
                  </p>

                  <Button href="/weekly-habit-tracker-app/" link="GATSBY">
                    Learn More
                  </Button>
                </div>

                <div className="flex items-end justify-center sm:max-w-xs sm:mx-auto">
                  <StaticImage
                    alt="Iphone App Splash Screen"
                    placeholder="blurred"
                    src="../images/app-mock-index.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </OnScreen>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulSuccessStory(
      filter: {
        name: {
          in: [
            "Harrison Loew"
            "Kanishka Desai"
            "Nick Johnson"
            "Linus Stieldorf"
            "Danny Lynch"
          ]
        }
      }
    ) {
      edges {
        node {
          coverImage {
            description
            gatsbyImageData(layout: CONSTRAINED)
          }
          id
          name
          testimonial {
            testimonial
          }
          title
        }
      }
    }
  }
`

export default Coaching
